import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import Board from 'react-trello';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import classNames from 'classnames';
import { clearSnapshotVariable } from 'state/api';
import { useFormatMessage } from 'hooks';
import { fetchStores, storesCleanUp } from 'state/actions/stores';
import { createComment } from 'state/actions/comments';
import { modifyOrderNew, payOrderValitorPay, payOrderSaltPay, sendEmail, exportOrderForStores, getOrderList } from 'state/actions/orders';
import { ORDER_STATUS, ORDER_ITEM_STATUS } from 'utils';
import ROLES from 'permissions/roles';
import OrderCard from 'components/OrderCard';
import ModalBox from 'components/ModalBox';
import ProgressBar from 'components/ProgressBar';
import { NumericFormat } from 'react-number-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import paths from '../Router/paths';
import classes from './OrdersBoard.module.scss';

const OrdersBoard = () => {
  const dispatch = useDispatch();
  const [ordersData, setOrdersData] = useState([]);
  let timer;

  const { isAdmin, role, userStores, storesList, storesLoading, authUserName, authUserId} = useSelector(
    (state) => ({
      isAdmin: state.auth.userData.isAdmin,
      role: state.auth.userData.role,
      authUserName: state.auth.userData.name || state.auth.userData.email,
      authUserId: state.auth.userData.id,
      userStores: state.auth.userData.additionalData?.stores || [],
      storesList: state.stores.data,
      storesLoading: state.stores.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (storesList.length === 0) {
      dispatch(fetchStores());
    } 

    return () => {
      dispatch(storesCleanUp());
      clearTimeout(timer);
    };
  }, [dispatch, isAdmin, storesList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryOptions = {
          queries: [{
            'attribute': 'ServiceData.Source.Status',
            'operator': '==',
            'value': 'completed'
          },
          {
            'attribute': 'ServiceData.Source.Status',
            'operator': '==',
            'value': 'processing'
          }],
          relationship: 'or'
        };

        const result = await getOrderList({newOrders: true, subQueries: queryOptions, observeCallback: setOrdersData});
        if(result.data){
          setOrdersData(result.data);
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();

    return () => clearSnapshotVariable('orders');
  }, []);

  const {
    REACT_APP_DEADLINE_DAYS_STORE
  } = process.env;

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [modalDisabled, setModalDisabled] = useState(false);
  const [missingAllProducts, setMissingAllProducts] = useState(false);
  const [currentOrderData, setCurrentOrderData] = useState({});
  const [orderItemsGrouped, setOrderItemsGrouped] = useState(null);
  const [userCompanyIds, setUserCompanyIds] = useState([]);

  useEffect(() => {
    if(userStores.length > 0){
      const companiesIds = userStores.filter(storeID => 
        storesList.find(store => store.id === storeID && store.integratorID))?.
        map(storeID => { 
          const currentStore = storesList.find(store => store.id === storeID);

          return currentStore.integratorID;
        })
      || [];

      setUserCompanyIds(companiesIds);
    }
  }, [userStores, storesList]);

  const groupByStore = (orderDetails) => { 
    const orderItems = Array.from(orderDetails.Items.values());

    return orderItems.reduce((result, currentValue) => {
      const resultItem = result;
      const group = (resultItem[currentValue.CompanyId] || {'Items': []});
      group.Items.push(currentValue);

      if(!group.StoreInfo){
        const storeInfo = storesList.find((store) => store.integratorID === currentValue.CompanyId) || {};
        const storeStatusInfo = orderDetails.StoreStatuses.find((store) => store.CompanyId === currentValue.CompanyId) || {};

        let percentageForStore;

        if(storeStatusInfo.PickedDateTime){
          const deadLineStore = moment(orderDetails.ServiceData.Source.ModDateTime).add(REACT_APP_DEADLINE_DAYS_STORE, 'day');

          const diffStore = moment.duration(deadLineStore.diff(moment(storeStatusInfo.PickedDateTime))).as('hours');
          percentageForStore = 100;
          if(diffStore > 0){
            percentageForStore = Math.ceil(100 - (diffStore / (REACT_APP_DEADLINE_DAYS_STORE * 24)) * 100);
          }
        }

        group.StoreInfo = {
          ...storeInfo,
          ...storeStatusInfo,
          percentageForStore
        };
      }

      resultItem[currentValue.CompanyId] = group;
      return resultItem;

    }, {}); 
  };

  useEffect(() => {
    if (currentOrderData.Items) {
      const updatedItems = groupByStore(currentOrderData);
      setOrderItemsGrouped(updatedItems);

      const isMissingAllProducts = currentOrderData.Items.every(item => item.DeliveryStatus === 'missing' || item.DeliveryStatus === 'canceled');
      setMissingAllProducts(isMissingAllProducts);
    }
  }, [currentOrderData]);


  const columnStyle = {
    padding: "20px 5px 0px",
  };

  const columnTitleStyle = {
    color: "#4a4a4a",
    fontSize: "16px",
  };

  const orderStatusArr = Object.keys(ORDER_STATUS);

  const data = {
    lanes: orderStatusArr.map((status, indexStatus) => {
      return ({
        id: status,
        title: ORDER_STATUS[status],
        titleStyle: columnTitleStyle,
        cards: ordersData.filter((order) => { 
          let findOrder = false;

          if(role === ROLES.STORE){
            const currentStores = order.StoreStatuses.filter((storeInfo) => 
              userCompanyIds.includes(storeInfo.CompanyId)
            );

            const filteredStores = currentStores.some((currentStore) => currentStore.StoreStatus === status );

            if(filteredStores){
              findOrder = currentStores.every((currentStore) => userCompanyIds.includes(currentStore.CompanyId) && currentStore.StoreStatus === status );

              if(!findOrder){
                let lowStatusIndex;

                currentStores.forEach(elem => {
                  const index = orderStatusArr.findIndex(orderStatusItem => elem.StoreStatus === orderStatusItem);
                  if (typeof lowStatusIndex === 'undefined') 
                    lowStatusIndex = index;

                  if(index < lowStatusIndex)
                    lowStatusIndex = index;
                });

                if(lowStatusIndex === indexStatus)
                  findOrder = true;
              }
            }
          }
          else if(status === order.StandardFields.DeliveryStatus){
            findOrder = true;
          }

          return findOrder;
          
          }).map((currentOrder) => {
          return ({
            id: currentOrder.id,
            laneId: status,
            externalId: currentOrder.ServiceData.Source.Identity,
            content: currentOrder.Billing.Firstname,
            date: currentOrder.ServiceData.Source.ModDateTime,
            pickedDateTime: currentOrder.StandardFields.PickedDateTime || '',
            deliveredDateTime: currentOrder.StandardFields.DeliveredDateTime || '',
            processing: currentOrder.StandardFields?.processing || false,
            note: currentOrder.StandardFields.Note
          });
        })
      });
    })
  };

  const components = {
    Card: OrderCard,
  };

  const handleDragEnd = async(orderId, sourceLaneId, targetLaneId) => {
    let access = false;

    if(sourceLaneId === targetLaneId) return true;

    if(sourceLaneId === 'cancelled' || targetLaneId === 'cancelled') return false;

    switch (role) {
      case ROLES.STORE:
        if(sourceLaneId === 'todo' && targetLaneId === 'in_picking')
          access = true;
        break;
      case ROLES.ADMIN:
        if(sourceLaneId === 'todo' && targetLaneId === 'in_picking' ||
          sourceLaneId === 'in_picking' && targetLaneId === 'dispatched' ||
          sourceLaneId === 'dispatched' && targetLaneId === 'received' ||
          sourceLaneId === 'received' && targetLaneId === 'ready' ||
          sourceLaneId === 'ready' && targetLaneId === 'delivered'
        )
        access = true;
        break;
      case ROLES.SERVICE_DESK:
        if(sourceLaneId === 'todo' && targetLaneId === 'in_picking' ||
          sourceLaneId === 'in_picking' && targetLaneId === 'dispatched' ||
          sourceLaneId === 'dispatched' && targetLaneId === 'received' ||
          sourceLaneId === 'received' && targetLaneId === 'ready' ||
          sourceLaneId === 'ready' && targetLaneId === 'delivered'
        )
          access = true;
        break;
      default:
        access = false;
    }

    const orderData = ordersData.find((order) => order.id === orderId);

    const newOrder = {
      ...orderData
    };

    if(access){
      if(newOrder.StandardFields.processing && newOrder.StandardFields.processing === true){
        toastr.error('', 'Please wait until the current process is finished.');
        access = false;
      }

      const commentMsg = `Order status changed from ${ORDER_STATUS[sourceLaneId]} to ${ORDER_STATUS[targetLaneId]}`;

      const commentData = {
        commentType: 'order',
        entityId: newOrder.id,
        shortEntityId: newOrder.ServiceData.Source.Identity,
        content: commentMsg,
        uid: authUserId,
        createdAt: moment().format()
      };

      if(role === ROLES.STORE){
        if(userCompanyIds.length){
          const storeIndex = newOrder.StoreStatuses.findIndex(storeItem => userCompanyIds.includes(storeItem.CompanyId));
          if(storeIndex >= 0)
            newOrder.StoreStatuses[storeIndex].StoreStatus = targetLaneId;
        }

        const isAllStoresSameStatus = newOrder.StoreStatuses.every(item => item.StoreStatus === targetLaneId);
        if(isAllStoresSameStatus){
          newOrder.StandardFields.DeliveryStatus = targetLaneId;

          await dispatch(exportOrderForStores(newOrder, sourceLaneId, targetLaneId));

          const commentId = await createComment(commentData);
          if(!newOrder.Comments) {
            newOrder.Comments = [];
          }
          newOrder.Comments.push(commentId);
        }
      }
      else{
        if(sourceLaneId === 'todo' && targetLaneId === 'in_picking'){
          const exportOrderResponse = await dispatch(exportOrderForStores(newOrder, sourceLaneId, targetLaneId));
          if(exportOrderResponse.payload.error){
            access = false;
          }
        }

        if(sourceLaneId === 'in_picking' && targetLaneId === 'dispatched'){
          const isAllStoresDispatched = newOrder.StoreStatuses.every(item => item.StoreStatus === 'dispatched');
          if(isAllStoresDispatched){
            const exportOrderResponse = await dispatch(exportOrderForStores(newOrder, sourceLaneId, targetLaneId));
            if(exportOrderResponse.payload.error){
              access = false;
            }
          }
          else{
            access = false;
            toastr.error('', 'Not all stores updated current order. Please check all product statuses.');
          }
        }

        if(sourceLaneId === 'dispatched' && targetLaneId === 'received'){
          const isAllStoresReceived = newOrder.StoreStatuses.every(item => item.StoreStatus === 'received');
          if(isAllStoresReceived){
            const exportOrderResponse = await dispatch(exportOrderForStores(newOrder, sourceLaneId, targetLaneId));
            if(exportOrderResponse.payload.error){
              access = false;
            }
          }
          else{
            access = false;
            toastr.error('', 'Please check all product statuses.');
          }
        }

        if(sourceLaneId === 'received' && targetLaneId === 'ready'){
          if(newOrder.StandardFields.processing && newOrder.StandardFields.processing === true){
            toastr.error('', 'Please wait payment process.');
            access = false;
          }
          else{
            if(newOrder.StandardFields.PaymentMethod === 'valitorpay'){
              await dispatch(payOrderValitorPay(newOrder));
            }

            if(newOrder.StandardFields.PaymentMethod === 'saltpay-rpg'){
              try{
                await payOrderSaltPay(newOrder);
              }
              catch(error){
                console.log(error);
                access = false;
              }
            }
          }
        }

        if(sourceLaneId === 'ready' && targetLaneId === 'delivered'){
          newOrder.StandardFields.DeliveredDateTime = `${(new Date()).toISOString().split('.')[0]}Z`;

          newOrder.StoreStatuses.forEach((storeItem, index) => {
            newOrder.StoreStatuses[index].StoreStatus = targetLaneId;
          });
        }

        if(access){
          if(targetLaneId !== 'ready'){
            const commentId = await createComment(commentData);

            if(!newOrder.Comments) {
              newOrder.Comments = [];
            }
            newOrder.Comments.push(commentId);
          }
          newOrder.StandardFields.DeliveryStatus = targetLaneId;
        }
      }
    }
    else{
      newOrder.StandardFields.processing = false;
      toastr.error('', 'You don\'t have permission to change the status of the order. ');
    }
    
    await modifyOrderNew(newOrder, false);

    return access;
  };

  const handleCardClick = (orderId, metadata, laneId) => {
    setCurrentOrderData(ordersData.find((order) => order.id === orderId));
    setModalType(laneId);

    if(laneId === 'todo' || laneId === 'in_picking'){
      if(role === ROLES.STORE || role === ROLES.ADMIN || role === ROLES.SERVICE_DESK){
        setShowModal(true);
      }
    }

    if(laneId === 'dispatched'){
      if(role === ROLES.SERVICE_DESK || role === ROLES.ADMIN || role === ROLES.STORE){
        setShowModal(true);
      }
    }

    if(laneId === 'received' || laneId === 'ready' || laneId === 'delivered' || laneId === 'cancelled'){
      if(role === ROLES.STORE || role === ROLES.SERVICE_DESK || role === ROLES.ADMIN ){
        setShowModal(true);
      }
    }

  };

  const ModalHeader = () => {

    return (
      <>
        <div className="is-size-5 mr-5">Order: <Link to={`/orders/${currentOrderData.id}`}><span className="has-text-weight-semibold">{ currentOrderData.ServiceData.Source.Identity }</span></Link></div>
        <div className="is-size-5 mr-5">{ currentOrderData.Billing.Firstname}</div>
        <div className="is-size-6">{ moment(currentOrderData.ServiceData.Source.ModDateTime).utc().format("DD.MM.YYYY - HH:mm") }</div>
      </>
    );
  };

  const handleChangeAvailability = async(event, SKU) => {
    setModalDisabled(true);
    const { target } = event;
    const { value } = target;

    const updateOrder = { ...currentOrderData };
    const indexItem = updateOrder.Items.findIndex(el => el.SKU === SKU);
    updateOrder.Items[indexItem].DeliveryStatus = value;  

    if(value === 'missing'){
      const missingProductMsg = `${authUserName} marked "${updateOrder.Items[indexItem].Name}" (${updateOrder.Items[indexItem].SKU}) product as missing`;

      const commentData = {
        commentType: 'missing_product',
        entityId: updateOrder.id,
        shortEntityId: updateOrder.ServiceData.Source.Identity,
        content: missingProductMsg,
        uid: authUserId,
        createdAt: moment().format()
      };

      const commentId = await createComment(commentData);
      if(!updateOrder.Comments) updateOrder.Comments = [];
      updateOrder.Comments.push(commentId);
    }

    if(role === ROLES.STORE){
      userCompanyIds.forEach(element => {
        const changeStoreStatus = updateOrder.Items
          .filter(item => item.CompanyId === element)
          .every(item => item.DeliveryStatus === 'missing' || item.DeliveryStatus === 'picked');

        if(changeStoreStatus){
          const storeIndex = updateOrder.StoreStatuses.findIndex(item => item.CompanyId === element);
          if(storeIndex >= 0){
            updateOrder.StoreStatuses[storeIndex].StoreStatus = 'dispatched';
            updateOrder.StoreStatuses[storeIndex].PickedDateTime = `${(new Date()).toISOString().split('.')[0]}Z`;
            setShowModal(false);
          }
        }
      });
    }

    const changeAllStoreStatus = updateOrder.Items
      .every(item => item.DeliveryStatus === 'missing' || item.DeliveryStatus === 'picked'); 
    
    if(role === ROLES.ADMIN || role === ROLES.SERVICE_DESK){
      if(changeAllStoreStatus){
        updateOrder.StoreStatuses.forEach((item, i) => {
          updateOrder.StoreStatuses[i].StoreStatus = 'dispatched';
          updateOrder.StoreStatuses[i].PickedDateTime = `${(new Date()).toISOString().split('.')[0]}Z`;;
        });
      }
    }

    const changeOrderStatus = updateOrder.StoreStatuses
      .every(item => item.StoreStatus === 'dispatched'); 

    if(changeOrderStatus){
      updateOrder.StandardFields.DeliveryStatus = 'dispatched';
      updateOrder.StandardFields.PickedDateTime = `${(new Date()).toISOString().split('.')[0]}Z`;

      const commentMsg = `Order status changed from ${ORDER_STATUS.in_picking} to ${ORDER_STATUS.dispatched}`;

      const commentData = {
        commentType: 'order',
        entityId: updateOrder.id,
        shortEntityId: updateOrder.ServiceData.Source.Identity,
        content: commentMsg,
        uid: authUserId,
        createdAt: moment().format()
      };

      const commentId = await createComment(commentData);
      if(!updateOrder.Comments) updateOrder.Comments = [];
      updateOrder.Comments.push(commentId);

      setShowModal(!showModal);
    }

    try{
      await modifyOrderNew(updateOrder);

      setModalDisabled(false);
      if(updateOrder.StandardFields.DeliveryStatus === 'dispatched'){
        dispatch(exportOrderForStores(updateOrder, 'in_picking', 'dispatched')).then(() => {
          const isSendCanceledEmail = updateOrder.Items.some(item => item.DeliveryStatus === 'canceled' || item.DeliveryStatus === 'missing');
          if(isSendCanceledEmail){
            sendEmail(updateOrder.id, "send-canceled-email-customer");
          }
        });
      }
    }
    catch (error) {
      console.log('Error');
    }
  };

  const handleConfirmMissingProducts = async() => {
    const updateOrder = { ...currentOrderData };

    updateOrder.StoreStatuses.forEach((storeItem, index) => {
      updateOrder.StoreStatuses[index].StoreStatus = 'received';
    });

    updateOrder.StandardFields.DeliveryStatus = 'received';

    await modifyOrderNew(updateOrder);
    setShowModal(!showModal);
  };

  const handleReceived = async(event) => {
    setModalDisabled(true);
    const updateOrder = { ...currentOrderData };
    const { target } = event;
    const { checked } = target;
    const indexItem = target.dataset.index;

    const productIndex = updateOrder.Items.findIndex((item => item.SKU === indexItem));
    if(checked){
      updateOrder.Items[productIndex].DeliveryStatus = 'received';
    }
    else{
      updateOrder.Items[productIndex].DeliveryStatus = 'picked';
    }
    
    Object.keys(orderItemsGrouped).forEach(groupCompanyId => {
      const isUpdateStoreStatus = updateOrder.Items
      .filter(item => item.CompanyId === groupCompanyId)
      .every(productItem => productItem.DeliveryStatus === 'received' || productItem.DeliveryStatus === 'missing' || productItem.DeliveryStatus === 'canceled');

      const storeIndex = updateOrder.StoreStatuses.findIndex((store => store.CompanyId === groupCompanyId));

      if(isUpdateStoreStatus){
        updateOrder.StoreStatuses[storeIndex].StoreStatus = 'received';
      }
      else{
        updateOrder.StoreStatuses[storeIndex].StoreStatus = 'dispatched';
      }
    });

    try{
      await modifyOrderNew(updateOrder);
      setCurrentOrderData(updateOrder);
      setModalDisabled(false);
    }
    catch(error){
      console.log(error);
    }
  };

  const onChangeDispatchStatus = async(event, SKU) => {
    setModalDisabled(true);
    const { target } = event;
    const { value } = target;

    const updateOrder = { ...currentOrderData };
    const indexItem = updateOrder.Items.findIndex(el => el.SKU === SKU);
    updateOrder.Items[indexItem].DeliveryStatus = value;  

    try{
      await modifyOrderNew(updateOrder);
      setCurrentOrderData(updateOrder);
      setModalDisabled(false);
    }
    catch(error){
      console.log(error);
    }
  };

  const ModalContent = () => {

    return (
      <>
        { (modalType === 'todo' || modalType === 'in_picking') &&
          <form>
            <table className="table">
              <tbody>
                { !!orderItemsGrouped &&
                  <>
                    { Object.keys(orderItemsGrouped).map((groupCompanyId) => {

                      return (
                        <Fragment key={groupCompanyId}>
                          { (role === ROLES.ADMIN || role === ROLES.SERVICE_DESK || (role === ROLES.STORE && userCompanyIds.includes(groupCompanyId))) &&
                            <>
                              <tr className={classes.storeItem}>
                                <td className="is-vcentered">
                                  { orderItemsGrouped[groupCompanyId].StoreInfo.logoUrl && 
                                    <img className={classes.storeImage} src={ orderItemsGrouped[groupCompanyId].StoreInfo.logoUrl } alt={ orderItemsGrouped[groupCompanyId].StoreInfo.StoreName }/>
                                  }
                                </td>
                                <td className="is-vcentered"><span className="is-flex is-size-3 has-text-weight-semibold py-3">{ orderItemsGrouped[groupCompanyId].StoreInfo.StoreName }</span></td>
                                { orderItemsGrouped[groupCompanyId].StoreInfo.percentageForStore &&
                                  <td className="is-vcentered">
                                    <div className={classes.progressBar}>
                                      <ProgressBar percentage={orderItemsGrouped[groupCompanyId].StoreInfo.percentageForStore} />
                                    </div>
                                  </td>
                                }
                              </tr>
                              { orderItemsGrouped[groupCompanyId].Items.map(({
                                Qty,
                                SKU,
                                Image,
                                Name,
                                UnitPrice,
                                DeliveryStatus,
                              }, index) => {
                                return(                             
                                  <tr key={SKU} className={classNames({[classes.isDisabledProduct] : DeliveryStatus === 'missing'})}>
                                    <td className="is-vcentered">
                                      { Image && 
                                        <img className={classes.productImage} src={Image} alt={Name}/>
                                      }
                                    </td>
                                    <td className="is-vcentered">{Name}</td>
                                    <td className="is-vcentered">{Qty} stk</td>
                                    <td className="is-vcentered">
                                      <NumericFormat value={UnitPrice* Qty} displayType='text' thousandSeparator suffix={` ${getSymbolFromCurrency(currentOrderData.StandardFields.Currency)}`} />
                                    </td>
                                    <td className="is-vcentered">
                                      <select
                                        disabled={ modalType === 'todo' && true }
                                        value={DeliveryStatus}
                                        onChange={(event) => handleChangeAvailability(event, SKU)}
                                        name="availability"
                                        data-index={index}
                                        className={classNames('select')}
                                      >
                                        { Object.keys(ORDER_ITEM_STATUS).filter(orderItemStatus => orderItemStatus !== 'received' && orderItemStatus !== 'canceled').map((status) => (
                                          <option key={status} value={status}>
                                            {ORDER_ITEM_STATUS[status]}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          }
                        </Fragment>
                      );
                    })}
                  </>
                }
              </tbody>
            </table>
          </form>
        }
        { (modalType === 'dispatched') &&
          <form>
            <div className="columns">
              <div className="column is-8">
                <table className="table">
                  <tbody>
                    { !!orderItemsGrouped &&
                      <>
                        { Object.keys(orderItemsGrouped).map((groupCompanyId, groupIndex) => {
                          return (
                            <Fragment key={groupCompanyId}>
                              { (role === ROLES.ADMIN || role === ROLES.SERVICE_DESK || (role === ROLES.STORE && userCompanyIds.includes(groupCompanyId))) &&
                                <>
                                  <tr className={classes.storeItem}>
                                    <td className="is-vcentered">
                                      { orderItemsGrouped[groupCompanyId].StoreInfo.logoUrl && 
                                        <img className={classes.storeImage} src={ orderItemsGrouped[groupCompanyId].StoreInfo.logoUrl } alt={ orderItemsGrouped[groupCompanyId].StoreInfo.StoreName }/>
                                      }
                                    </td>
                                    <td className="is-vcentered"><span className="is-flex is-size-3 has-text-weight-semibold py-3">{ orderItemsGrouped[groupCompanyId].StoreInfo.StoreName }</span></td>
                                    { orderItemsGrouped[groupCompanyId].StoreInfo.percentageForStore &&
                                      <td className="is-vcentered">
                                        <div className={classes.progressBar}>
                                          <ProgressBar percentage={orderItemsGrouped[groupCompanyId].StoreInfo.percentageForStore} />
                                        </div>
                                      </td>
                                    }
                                  </tr>
                                  { orderItemsGrouped[groupCompanyId].Items.map(({
                                    Qty,
                                    SKU,
                                    Image,
                                    Name,
                                    UnitPrice,
                                    DeliveryStatus,
                                  }, index) => {
                                    return(                             
                                      <tr key={SKU} className={classNames({[classes.isDisabledProduct] : DeliveryStatus === 'missing' || DeliveryStatus === 'canceled'})}>
                                        <td className="is-vcentered">
                                          { Image && 
                                            <img className={classes.productImage} src={Image} alt={Name}/>
                                          }
                                        </td>
                                        <td className="is-vcentered">{Name}</td>
                                        <td className="is-vcentered">{Qty} stk</td>
                                        <td className="is-vcentered">
                                          <NumericFormat value={UnitPrice* Qty} displayType='text' thousandSeparator suffix={` ${getSymbolFromCurrency(currentOrderData.StandardFields.Currency)}`} />
                                        </td>
                                        <td className="is-vcentered">
                                          <select
                                            value={DeliveryStatus}
                                            onChange={(event) => onChangeDispatchStatus(event, SKU)}
                                            name="dispatch-status"
                                            data-index={index}
                                            className={classNames('select')}
                                          >
                                            <option key="missing" value="missing">Missing</option>
                                            <option key="picked" value="picked">Picked</option>
                                            <option key="received" value="received">Received</option>
                                          </select>
                                        </td>
                                        { (role === ROLES.ADMIN || role === ROLES.SERVICE_DESK) &&
                                          <td className="is-vcentered">
                                            { DeliveryStatus !== 'missing' && DeliveryStatus !== 'canceled' && 
                                              <label className="b-checkbox checkbox" htmlFor={`isReseived-${groupIndex}-${index}`}>
                                                <input
                                                  disabled={DeliveryStatus === 'missing' || DeliveryStatus === 'canceled'}
                                                  id={`isReseived-${groupIndex}-${index}`}
                                                  type="checkbox"
                                                  name="isReseived"
                                                  data-index={SKU}
                                                  checked={DeliveryStatus === 'received'}
                                                  onChange={handleReceived}
                                                />
                                                <span className="check is-primary" />
                                              </label>
                                            }
                                          </td>
                                        }
                                      </tr>
                                    );
                                  })}
                                </>
                              }
                            </Fragment>
                          );
                        })}
                      </>
                    }
                  </tbody>
                </table>
              </div>
              <div className="column is-4">
                <div className="box">
                  <div className="content">
                    { currentOrderData.Shipping.Firstname && 
                      <p><strong>Name:</strong> { currentOrderData.Shipping.Firstname }</p>
                    }

                    { currentOrderData.Shipping.AddressLine1 && 
                      <p><strong>Address:</strong> { currentOrderData.Shipping.AddressLine1 }, { currentOrderData.Shipping.City }, { currentOrderData.Shipping.ZipCode }</p>
                    }
                    
                    { currentOrderData.Shipping.Email && 
                      <p><strong>Email:</strong> { currentOrderData.Shipping.Email }</p>
                    }

                    { currentOrderData.Shipping.Phone && 
                      <p><strong>Phone:</strong> { currentOrderData.Shipping.Phone }</p>
                    }
                    
                    { currentOrderData.Shipping.SSID && 
                      <p><strong>SSID:</strong> { currentOrderData.Shipping.SSID }</p>
                    }

                    { currentOrderData.ShippingLine.Description && 
                      <p><strong>Shipping Method:</strong>{ `${currentOrderData.ShippingLine.Description} (${currentOrderData.ShippingLine.ShipmentMethodCode})` }</p>
                    }                 
                    
                    { currentOrderData.StandardFields.Note && 
                      <p><strong>Note:</strong> { currentOrderData.StandardFields.Note }</p>
                    }
                  </div>
                </div>
              </div>
            </div>
            { missingAllProducts && (role === ROLES.ADMIN || role === ROLES.SERVICE_DESK) &&
              <div className="notification is-warning is-light">
                The current order all products with the status <strong>missing</strong> or <strong>canceled</strong>.
                <div className="mt-2">
                  <span onClick={handleConfirmMissingProducts} className="button is-info">Сonfirm</span>
                </div>
              </div>
            }
          </form>
        }
        { (modalType === 'received' || modalType === 'ready' || modalType === 'delivered' || modalType === 'cancelled') &&
          <div className="columns">
            <div className="column is-8">
              <table className="table">
                <tbody>
                  { !!orderItemsGrouped &&
                    <>
                      { Object.keys(orderItemsGrouped).map((groupCompanyId) => {
                        return (
                          <Fragment key={groupCompanyId}>
                            { (role === ROLES.ADMIN || role === ROLES.SERVICE_DESK || (role === ROLES.STORE && userCompanyIds.includes(groupCompanyId))) &&
                              <>
                                <tr className={classes.storeItem}>
                                  <td className="is-vcentered">
                                    { orderItemsGrouped[groupCompanyId].StoreInfo.logoUrl && 
                                      <img className={classes.storeImage} src={ orderItemsGrouped[groupCompanyId].StoreInfo.logoUrl } alt={ orderItemsGrouped[groupCompanyId].StoreInfo.StoreName }/>
                                    }
                                  </td>
                                  <td className="is-vcentered"><span className="is-flex is-size-3 has-text-weight-semibold py-3">{ orderItemsGrouped[groupCompanyId].StoreInfo.StoreName }</span></td>
                                  { orderItemsGrouped[groupCompanyId].StoreInfo.percentageForStore &&
                                    <td className="is-vcentered">
                                      <div className={classes.progressBar}>
                                        <ProgressBar percentage={orderItemsGrouped[groupCompanyId].StoreInfo.percentageForStore} />
                                      </div>
                                    </td>
                                  }
                                </tr>
                                { orderItemsGrouped[groupCompanyId].Items.map(({
                                  Qty,
                                  SKU,
                                  Image,
                                  Name,
                                  UnitPrice,
                                  DeliveryStatus,
                                }) => {
                                  return(                             
                                    <tr key={SKU} className={classNames({[classes.isDisabledProduct] : DeliveryStatus === 'missing'})}>
                                      <td className="is-vcentered">
                                        { Image && 
                                          <img className={classes.productImage} src={Image} alt={Name}/>
                                        }
                                      </td>
                                      <td className="is-vcentered">{Name}</td>
                                      <td className="is-vcentered">{Qty} stk</td>
                                      <td className="is-vcentered">
                                        <NumericFormat value={UnitPrice * Qty} displayType='text' thousandSeparator suffix={` ${getSymbolFromCurrency(currentOrderData.StandardFields.Currency)}`} />
                                      </td>
                                      <td className="is-vcentered">
                                        { DeliveryStatus }
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            }
                          </Fragment>
                        );
                      })}
                    </>
                  }
                </tbody>
              </table>
            </div>
            <div className="column is-4">
              <div className="box">
                <div className="content">
                  { currentOrderData.Shipping.Firstname && 
                    <p><strong>Name:</strong> { currentOrderData.Shipping.Firstname }</p>
                  }

                  { currentOrderData.Shipping.AddressLine1 && 
                    <p><strong>Address:</strong> { currentOrderData.Shipping.AddressLine1 }, { currentOrderData.Shipping.City }, { currentOrderData.Shipping.ZipCode }</p>
                  }
                  
                  { currentOrderData.Shipping.Email && 
                    <p><strong>Email:</strong> { currentOrderData.Shipping.Email }</p>
                  }

                  { currentOrderData.Shipping.Phone && 
                    <p><strong>Phone:</strong> { currentOrderData.Shipping.Phone }</p>
                  }
                  
                  { currentOrderData.Shipping.SSID && 
                    <p><strong>SSID:</strong> { currentOrderData.Shipping.SSID }</p>
                  }                 
                  
                  { currentOrderData.ShippingLine.Description && 
                    <p><strong>Shipping Method:</strong> { `${currentOrderData.ShippingLine.Description} (${currentOrderData.ShippingLine.ShipmentMethodCode})` }</p>
                  }

                  { currentOrderData.StandardFields.Note && 
                    <p><strong>Note:</strong> { currentOrderData.StandardFields.Note }</p>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">{useFormatMessage('Orders.orders')}</h1>
              </div>
            </div>
          </div>
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="tabs is-boxed">
                  <ul>
                    <li>
                      <Link
                        to={paths.ORDERS}
                      >
                        <span className="icon is-small"><i className="mdi mdi-format-list-bulleted" aria-hidden="true"/></span>
                        <span>{useFormatMessage('Orders.viewList')}</span>
                      </Link>
                    </li>
                    <li className="is-active">
                      <Link
                        to={paths.ORDERS_BOARD}
                      >
                        <span className="icon is-small"><i className="mdi mdi-view-dashboard" aria-hidden="true"/></span>
                        <span>{useFormatMessage('Orders.viewBoard')}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="columns is-mobile is-centered">
          { storesLoading ? (
              <div className="column is-narrow">
                <Loader />
              </div>
          ) : 
          (
            <Board 
              className={classes.boardContainer}
              laneStyle={columnStyle}
              data={data} 
              components={components}
              laneDraggable={false}
              handleDragEnd={handleDragEnd}
              onCardClick={handleCardClick}
            />
          )}


          <ModalBox modalDisabled={modalDisabled} closeClickOutside onClose={handleShowModal} show={showModal} header={<ModalHeader/>} content={<ModalContent/>} modalWidth='full'/>     
        </div>
        
      </section>
    </>
  );
};

export default OrdersBoard;